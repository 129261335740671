<template>
  <CardWrapper
    v-bind="$attrs"
    class="rounded-x-lg pa-4"
    data-test="track-card"
  >
    <VRow>
      <VCol cols="auto">
        <VResponsive
          v-if="image"
          :aspect-ratio="16/9"
        >
          <VImg
            class="rounded-lg"
            :src="image"
            height="96"
            width="96"
          />
        </VResponsive>
        <VAvatar
          v-else
          size="96"
          class="rounded-lg"
          color="tt-light-blue pale"
          data-test="track-card-avatar"
        >
          <VIcon color="tt-light-blue">
            fal fa-image-polaroid
          </VIcon>
        </VAvatar>
      </VCol>
      <VCol>
        <VContainer class="fill-height pa-0">
          <VRow
            class="fill-height"
            no-gutters
            align-content="space-between"
          >
            <VCol cols="12">
              <div
                class="tt-text-title-2"
                data-test="track-card-title"
              >
                {{ title }}
              </div>
            </VCol>
            <VCol cols="12">
              <VRow
                justify="space-between"
                align="center"
                data-test="track-card-progress"
              >
                <VCol>
                  <VProgressLinear
                    :value="progress"
                    rounded
                  />
                </VCol>
                <VCol cols="auto">
                  <div class="tt-text-body-2">
                    {{ completedLevels }} {{ $t('common.of') }} {{ totalLevels }}
                  </div>
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VContainer>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'TracksCard',
  components: { CardWrapper },
  props: {
    title: {
      type: String,
      default: '',
    },
    completedLevels: {
      type: Number,
      default: 0,
    },
    totalLevels: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: '',
    },
  },
  computed: {
    progress() {
      return Math.round((this.completedLevels * 100) / this.totalLevels);
    },
  },
};
</script>

<style scoped>

</style>
