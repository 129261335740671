<template>
  <VContainer>
    <VRow align="center">
      <VCol>
        <h1
          data-test="tracks-view-title"
          class="tt-text-headline-1"
        >
          {{ $t('menu.tracks') }}
        </h1>
      </VCol>
      <VCol cols="auto">
        <VBtn
          color="orange"
          @click="addTracks"
        >
          Добавить треков
        </VBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-for="item in tracks"
        :key="'track-card-'+item.id"
        cols="12"
      >
        <TracksCard
          link
          :title="item.title"
          :to="{name : Names.R_APP_TRACK_VIEW,params : {id : item.id}}"
          :completed-levels="item.progress"
          :total-levels="item.total"
          :image="item.image"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <template v-if="!tracks.length">
          <VRow>
            <VCol>
              <EmptySection
                :show-arrow="false"
                :title="$t('tracks.has_no_assigned_tracks')"
                :text="$t('contact_manager_or_support')"
              />
            </VCol>
          </VRow>
        </template>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import TracksCard from '@/components/cards/TracksCard.vue';
import EmptySection from '@/components/shared/EmptySection.vue';

export default {
  name: 'TracksView',
  components: {
    EmptySection,
    TracksCard,
  },
  inject: ['Names'],
  data() {
    return {
      tracks: [],
    };
  },
  methods: {
    addTracks() {
      this.tracks = [...Array(5)].map((_, index) => ({
        id: index,
        title: `Заголовок трека - ${index}`,
        progress: index + 5,
        total: (index + 1) + 10,
        image: index % 2 === 0 ? 'https://picsum.photos/96' : '',
      }));
    },
  },
};
</script>

<style scoped>

</style>
